import React from "react"

const PageTwo = ({
  setUploadedFileName,
  primaryLogoFileName,
  altLogoFileName,
  show,
  dataFromlocalStorage,
  setValue,
}) => {
  const pageDisplayStyle = show
    ? {
        display: "block",
      }
    : {
        display: "none",
      }

  return (
    <div className="page-two page" id="page-two" style={pageDisplayStyle}>
      <div>
        <div className="field name-field">
          <label htmlFor="company-primary-color">
            What’s your company’s primary color? Provide Hex code (ex. #1F54A7)
          </label>
          <input
            type="text"
            name="company-primary-color"
            id="company-primary-color"
            onChange={e => setValue("company-primary-color", e.target.value)}
            defaultValue={
              dataFromlocalStorage
                ? dataFromlocalStorage["company-primary-color"]
                : ""
            }
            required
          ></input>
        </div>
        <div className="field email-field">
          <label htmlFor="other-colors">
            Does your company use any other color(s)? If so, provide Hex codes
            (ex. #1F54A7)
          </label>
          <input
            type="text"
            name="other-colors"
            id="other-colors"
            onChange={e => setValue("other-colors", e.target.value)}
            defaultValue={
              dataFromlocalStorage ? dataFromlocalStorage["other-colors"] : ""
            }
          ></input>
        </div>
        <p className="file-upload-text">
          Upload company logo (.provide a zip file when uploading .ai, .svg or
          psd documents)
        </p>
        <div className="file centered-text">
          <label htmlFor="primary-logo" className="custom-file-upload">
            <input
              type="file"
              name="primary-logo"
              id="primary-logo"
              className="file-upload"
              onChange={e => {
                setUploadedFileName("primary-logo", e.target.files)
              }}
              required
            />
            <p className="choose-a-file">Click Here To Upload A File</p>
            <p id="fileUploadedName">{primaryLogoFileName}</p>
          </label>
        </div>
        <p className="file-upload-text">
          Upload alternative version of logo (.provide a zip file when uploading
          .ai, .svg or psd documents) *Optional
        </p>
        <div className="file centered-text">
          <label htmlFor="alt-logo" className="custom-file-upload">
            <input
              type="file"
              name="alt-logo"
              id="alt-logo"
              className="file-upload"
              onChange={e => {
                setUploadedFileName("alt-logo", e.target.files)
              }}
            />
            <p className="choose-a-file">Click Here To Upload A File</p>
            <p id="fileUploadedNamefile-two">{altLogoFileName}</p>
          </label>
        </div>
      </div>
    </div>
  )
}

export default PageTwo
