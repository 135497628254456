import React, { useState, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import Layout from '../../components/Layout';
import Seo from '../../components/Seo';
import Loader from '../../components/Loader';
import PageOne from './ProQuestionnairePageOne/ProQuestionnairePageOne';
import PageTwo from './ProQuestionnairePageTwo/ProQuestionnairePageTwo';
import PageThree from './ProQuestionnairePageThree/ProQuestionnairePageThree';

import './styles.scss';

const ProQuestionnaire = () => {
    const [currentPage, setCurrentPage] = useState(1);
    const [primaryLogoFileName, setPrimaryLogoFileName] = useState('');
    const [altLogoFileName, setAltLogoFileName] = useState('');
    const [submittingForm, setSubmittingForm] = useState(false);
    const [submissionError, setSubmissionError] = useState(false);
    const [submitSuccessful, setSubmissionSuccessful] = useState(false);
    const [submitBtnClicked, setSubmitBtnClicked] = useState(false);
    const [shouldPageOneBeShown, setShouldPageOneBeShown] = useState(true);
    const [shouldPageTwoBeShown, setShouldPageTwoBeShown] = useState(false);
    const [shouldPageThreeBeShown, setShouldPageThreeBeShown] = useState(false);
    const [saveFiles, setSaveFiles] = useState(false);
    const [dataFromlocalStorage, setdataFromlocalStorage] = useState();
    const { getValues, setValue } = useForm();

    useEffect(() => {
        retrieveDataFromLocalStorage();
        setCurrentPageDisplay();
    }, [currentPage]);

    const scrollToTop = () => {
        window.scrollTo({ top: 0 });
    };

    const retrieveDataFromLocalStorage = () => {
        if (localStorage.getItem('proQuestionnaireData') !== null) {
            const savedValues = JSON.parse(
                localStorage.getItem('proQuestionnaireData')
            );
            setdataFromlocalStorage(savedValues);
            for (let key in savedValues) {
                setValue(key, savedValues[key]);
            }
        }
    };

    const checkUploadedFileSize = file => {
        if (file.size > 52428800) {
            alert('File is too big! Max resume size is 50MB.');
            file.value = '';
            return false;
        }
        return true;
    };

    const setUploadedFileName = (primaryOrAltLogoField, file) => {
        if (checkUploadedFileSize(file[0])) {
            const selectedFile = file[0].name.split('\\');
            localStorage.setItem(
                'proQuestionnaireData',
                JSON.stringify(getValues())
            );

            if (primaryOrAltLogoField === 'primary-logo') {
                setValue('primary-logo', file);
                setPrimaryLogoFileName(
                    'File Uploaded: ' + selectedFile[selectedFile.length - 1]
                );
            } else {
                setAltLogoFileName(
                    'File Uploaded: ' + selectedFile[selectedFile.length - 1]
                );
            }
        }
    };

    const submitForm = data => {
        setSubmittingForm(true);
        setSubmissionError(false);

        const formName = document.getElementById('pro-questionnaire');

        fetch(formName.getAttribute('action'), {
            method: 'POST',
            body: new FormData(formName),
        }).then(res => {
            if (res.status === 200) {
                setSubmissionSuccessful(true);
            } else {
                setSubmittingForm(false);
                setSubmissionError(true);
            }
        });
    };

    const saveForm = () => {
        localStorage.setItem(
            'proQuestionnaireData',
            JSON.stringify(getValues())
        );
    };

    const getPageTitle = () => {
        let pageTitle = 'The Basics';
        let setCurrentPage = currentPage === null ? 3 : currentPage;
        if (currentPage === 2) {
            pageTitle = 'Design';
        } else if (currentPage === 3) {
            pageTitle = 'Emails & Landing Pages';
        }

        return `Section ${setCurrentPage} Out Of 3: ${pageTitle}`;
    };

    const setCurrentPageDisplay = () => {
        if (submitBtnClicked) {
            setShouldPageOneBeShown(true);
            setShouldPageTwoBeShown(true);
            setShouldPageThreeBeShown(true);
        } else {
            setShouldPageOneBeShown(false);
            setShouldPageTwoBeShown(false);
            setShouldPageThreeBeShown(false);

            switch (currentPage) {
                case 2:
                    setShouldPageTwoBeShown(true);
                    break;
                case 3:
                    setShouldPageThreeBeShown(true);
                    break;
                default:
                    setShouldPageOneBeShown(true);
            }
        }
    };

    return (
        <Layout navTheme="light">
            <Seo title="Pro Questionnaire " />
            <div className="pro-questionnaire">
                <div className="flexed-mobile stretch">
                    <div className="rel z-index-9 small-width min-height-100">
                        <h2>{getPageTitle()}</h2>
                        <form
                            id="pro-questionnaire"
                            style={{
                                display:
                                    submitSuccessful || saveFiles
                                        ? 'none'
                                        : 'block',
                            }}
                            className="purple-form ProQuestionnaire"
                            method="POST"
                            name="Pro Questionnaire"
                            action="/pro-questionnaire/#thanks"
                            encType="multipart/form-data"
                            netlify="true"
                            netlify-honeypot="bot-field"
                            data-category="pro-questionnaire"
                            onSubmit={e => {
                                e.preventDefault();
                                submitForm();
                            }}
                        >
                            {submittingForm && (
                                <>
                                    <p className="submitting-notice">
                                        Submitting form. <br /> Please Wait...
                                    </p>
                                    <Loader borderColor="#f30070" />
                                </>
                            )}
                            {submissionError && (
                                <p id="error-msg" className="show-error">
                                    Looks like there was a problem submitting
                                    your form. <br />
                                    Please ensure ALL form fields are filled out
                                    and try again.
                                </p>
                            )}
                            <input
                                type="hidden"
                                name="form-name"
                                value="pro-questionnaire"
                            />
                            <input type="hidden" name="bot-field" id="bot" />
                            <div
                                style={{
                                    display: submittingForm ? 'none' : 'block',
                                }}
                            >
                                <PageOne
                                    show={shouldPageOneBeShown}
                                    dataFromlocalStorage={dataFromlocalStorage}
                                    setValue={setValue}
                                />
                                <PageTwo
                                    setUploadedFileName={setUploadedFileName}
                                    primaryLogoFileName={primaryLogoFileName}
                                    altLogoFileName={altLogoFileName}
                                    show={shouldPageTwoBeShown}
                                    dataFromlocalStorage={dataFromlocalStorage}
                                    setValue={setValue}
                                />
                                <PageThree
                                    show={shouldPageThreeBeShown}
                                    dataFromlocalStorage={dataFromlocalStorage}
                                    setValue={setValue}
                                />
                            </div>
                            {!submittingForm && (
                                <div className="space-between form-navigation">
                                    <div className="flex space-between">
                                        <button
                                            onClick={() => {
                                                scrollToTop();
                                                saveForm();
                                                setCurrentPage(currentPage - 1);
                                            }}
                                            className="page-control"
                                            style={{
                                                display:
                                                    currentPage === 2 ||
                                                    currentPage === 3
                                                        ? 'block'
                                                        : 'none',
                                            }}
                                            type="button"
                                        >
                                            Previous
                                        </button>
                                        <div className="flex margin-left-auto">
                                            <button
                                                type="button"
                                                className="save-button"
                                                onClick={() => {
                                                    saveForm();
                                                    setSaveFiles(true);
                                                    setCurrentPage(null);
                                                }}
                                            >
                                                Save & Continue Later
                                            </button>
                                            <button
                                                onClick={() => {
                                                    scrollToTop();
                                                    saveForm();
                                                    setCurrentPage(
                                                        currentPage + 1
                                                    );
                                                }}
                                                className="page-control next"
                                                style={{
                                                    display:
                                                        currentPage === 1 ||
                                                        currentPage === 2
                                                            ? 'block'
                                                            : 'none',
                                                }}
                                                type="button"
                                            >
                                                Next
                                            </button>
                                            <button
                                                type="submit"
                                                className="pink-button"
                                                onClick={() => {
                                                    setSubmitBtnClicked(true);
                                                    setCurrentPage(null);
                                                }}
                                                style={{
                                                    display:
                                                        currentPage === 3 ||
                                                        submitBtnClicked
                                                            ? 'block'
                                                            : 'none',
                                                }}
                                            >
                                                SEND
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            )}
                        </form>
                        {submitSuccessful && (
                            <div
                                className="contact-thank-you reach"
                                id="thanks"
                            >
                                <h5>
                                    Your form has been successfully submitted!
                                    We'll be in touch shortly.
                                </h5>
                            </div>
                        )}
                        {saveFiles && (
                            <div className="contact-thank-you reach" id="saved">
                                <h5>
                                    Your form has been saved, but NOT submitted.
                                    Please return to{' '}
                                    <a
                                        className="white-text"
                                        href="/pro-questionnaire/"
                                    >
                                        https://glasshive.com/pro-questionnaire/
                                    </a>
                                    when you are ready to continue.
                                </h5>
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </Layout>
    );
};
export default ProQuestionnaire;
